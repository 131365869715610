import NoRowsOverlay from '@/components/atoms/Icon/NoRowsOverlay'
import SelectField from '@/components/atoms/SelectField'
import CustomPagination from '@/components/molecules/Pagination'
import { siteApi } from '@/ghgApi'
import { Site } from '@/openapi'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import dayjs from 'dayjs'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { limitOptions } from '../../accounts'

const useStyle = makeStyles({
    container: {
        marginTop: 20,
        maxWidth: theme.contentContainer.width,
    },
    pagination: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    tableSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    link: {
        marginTop: 20,
        textAlign: 'right',
        '& > a': {
            fontSize: 14,
            color: theme.colors.primaryN,
        },
    },
    // table
    tableContainer: {
        marginTop: 20,
    },
    headerCell: {
        backgroundColor: '#F7F7F7',
        fontWeight: 600,
        fontSize: 14,
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#FBFBFB',
        },
    },
    bodyCell: {
        fontSize: 14,
    },
})

export default function Views() {
    const headers = [
        { width: 300, text: '拠点名' },
        { width: 300, text: '利用開始月' },
        { width: '100%', text: 'ステータス' },
    ]

    const [listSites, setListSites] = useState<Site[]>([])
    const [page, setPage] = useState<number>(0)
    const [limit, setLimit] = useState<number>(10)
    const [totalSites, setTotalSite] = useState<number>(0)
    const classes = useStyle()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await siteApi.listSite(undefined, page * 10, limit)
                const { total, data } = res.data
                if (total && data) {
                    setTotalSite(total)
                    setListSites(data)
                }
            } catch (err) {
                console.warn(err)
            }
        }
        fetchData()
    }, [page, limit])

    return (
        <div className={classes.container}>
            <div className={classes.tableSection}>
                <span>
                    全{totalSites}件中{
                        limit > totalSites ? totalSites : limit
                    }件を表示中
                </span>
                <div className={classes.tableSection}>
                    表示件数：
                    <SelectField
                        size="sm"
                        style={{ width: 65 }}
                        menuItems={limitOptions}
                        value={limit}
                        onChange={(e) => setLimit(e.target.value as number)}
                    />
                    &nbsp;件
                </div>
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((h, i) => (
                                <TableCell
                                    key={i}
                                    style={{ width: h.width, minWidth: h.width, maxWidth: h.width }}
                                    className={classes.headerCell}
                                >
                                    {h.text}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listSites.length ? (
                            listSites.map((site) => {
                                const year = dayjs(site.createdDate).year()
                                const month = dayjs(site.createdDate).month() + 1
                                return (
                                    <TableRow className={classes.tableRow} key={site.id}>
                                        <TableCell className={classes.bodyCell}>{site.name}</TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            {year}年{month}月
                                        </TableCell>
                                        <TableCell className={classes.bodyCell}>
                                            {site.status === 1 ? '継続中' : '解約'}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    <NoRowsOverlay />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.link}>
                <Link to={ROUTES.PLANS}>各種プランの情報を見る</Link>
            </div>
            <div className={classes.pagination}>
                {listSites.length ? (
                    <CustomPagination withApi page={page} setPage={setPage} total={totalSites} rowPerPage={limit} />
                ) : null}
            </div>
        </div>
    )
}
